// eslint-disable-next-line no-redeclare,no-unused-vars
function buildDashboard(cont) {
  var colNo = localStorage.getItem('dashboardColNo');
  if (!colNo) colNo = 4;

  var cont1 = $('<div id="dashboard" class="flex-fill py-3"></div>').appendTo(
    cont
  );
  var cont2 = $('<div class="container-fluid"></div>').appendTo(cont1);
  var columnsOnMobileDevice = getScreenWidth() < 1000 ? 1 : 2;

  dashboard = $(
    '<div class="row row-cols-1 row-cols-md-' +
      columnsOnMobileDevice +
      ' row-cols-xxl-' +
      colNo +
      ' g-4" data-mdb-perfect-scrollbar="true"></div>'
  ).appendTo(cont2);
  new mdb.PerfectScrollbar(dashboard[0], {suppressScrollX: true});

  // dashboard events
  dashboard.show = function () {
    // if (views.overview) views.overview.close(true);
    // if (views.detail) views.detail.close(true);
    $.each(viewStack, function (i, view) {
      view.close({noViewsHandle: true, noOverviewHandle: true});
    });
    cont1.removeClass('d-none');
    handleMenuActive();
    views.addClass('d-none');
  };
  dashboard.hide = function () {
    cont1.addClass('d-none');
    views.removeClass('d-none');
  };
  dashboard.update = function () {
    ajax({
      object: 'start',
      callback: fillDashboard,
      callback2: function () {
        // contextMenu(cont.children(), context, contextFunc2);
      },
      send: {update: true},
    });
  };

  // start boxes
  if (!fields.start) fields.start = {};
  dashboard.sortable({
    handle: '.card-title',
    placeholder: 'mb-5 bg-warning rounded-1',
    forcePlaceholderSize: true,
    cursor: 'move',
    update: function () {
      parameter.start.boxes = dashboard
        .find('.dashboard-card[key]')
        .map(function () {
          return $(this).attr('key');
        })
        .get();
      saveSettings({
        object: 'start',
        key: 'boxes',
        value: parameter.start.boxes,
      });
    },
  });

  // context menu on dashboard
  var context = {
    order: info.context.chooseCards,
    update: info.context.updateCards,
  };
  var contextFunctions = function (type) {
    if (type == 'order') {
      parameter.start.boxes = dashboard
        .find('.dashboard-card[key]')
        .map(function () {
          return $(this).attr('key');
        })
        .get();
      var cardFields = {};
      $.each(fields.start, function (key, settings) {
        if (settings.hidden && checkWhere({}, {role: settings.hidden})) return;
        cardFields[key] = settings;
      });
      sorter({
        title: 'Dashboardkarten',
        fields: cardFields,
        list: parameter.start.boxes,
        save: function (value) {
          parameter.start.boxes = value;
          saveSettings(
            {object: 'start', key: 'boxes', value: parameter.start.boxes},
            function () {
              dashboard.update();
            }
          );
        },
      });
    } else if (type == 'update') dashboard.update();
  };
  contextMenu(cont1, context, contextFunctions);

  if (parameter.start.start)
    $('nav [data-ident=' + parameter.start.start + ']').trigger(click); // initial list
}

// eslint-disable-next-line no-redeclare,no-unused-vars
function showDashboard() {
  dashboard.show();
}
